import React, { useEffect, useState, useRef, createContext } from "react";
import { toast, ToastContainer } from 'react-toastify'
import { Navbar } from "../index";
import { useNavigate } from "react-router-dom";
import "./index.css";
import axios from "axios";
import config from "../../config";



export const homeRefContext = createContext();
export function ContactUs() {
  let navigate = useNavigate();
  function Redirect(url) {
    navigate(url);
  }
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [subject, setSbuject] = useState(null);
  const [message, setMessage] = useState(null);
  const [hovered, setHovered] = useState(false);
  const [checkSent, setSent] = useState(false)
  const toggleHover = () => setHovered(!hovered);
  async function sendEmail(e) {
    e.preventDefault();
    await console.log("started the sending")


    try {
      await axios.post(`${config.API_URL}/send-mail`, {
        name,
        email,
        subject,
        message
      }).then((data) => {
        console.log(data)
        if (data.data.message === "Ok" || data.status == 200) setSent(true);




        e.target.reset();

        setTimeout(() => {
          setSent(false)
        }, 2500);





      });

    } catch (error) {
      console.log(error)
    }

    await console.log("Ended")




  }

  return (
    <div>
      <Navbar />
      <div className={checkSent ? "success on" : "success"}>
        <div className="success2">
          <p>Sent successfully</p>
        </div>
      </div>
      <div className="contact">
        <h1>Contact Me (under maintenance)</h1>
        <h2>Email: Ali@ialawi.dev</h2>
        <form onSubmit={sendEmail}>
          <div className="login-page">
            <div className="login-content">
              <span>Name:</span>
              <input
                name="name"
                required={true}
                className="input-username-password"
                type={"text"}
                onChange={(e) => setName(e.target.value)}
                disabled
              />
              <br />
              <span>Email:</span>
              <input
                name="email"
                required={true}
                className="input-username-password"
                type={"email"}
                onChange={(e) => setEmail(e.target.value)}
                disabled
              />
              <br />
              <span>Subject:</span>
              <input
                name="subject"
                required={true}
                className="input-username-password"
                type={"text"}
                onChange={(e) => setSbuject(e.target.value)}
                disabled
              />

              <br />
              <span>Message:</span>
              <textarea
                name="message"
                required={true}
                className="input-username-password"
                onChange={(e) => setMessage(e.target.value)}
                disabled
                cols="10"
                rows={"8"}
              />
              <br />
              <div className="btn-send">
                <button
                  onMouseEnter={toggleHover}
                  onMouseLeave={toggleHover}
                  type="submit"
                  disabled
                >
                  <i className={"bx bx-send"}></i>Send
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="next-page-about">
        <i onClick={() => Redirect('/about')} class="bx bx-up-arrow-alt" ></i>
      </div>
      <ToastContainer />
    </div>
  );
}
