import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Landing, AboutPage, ContactUs } from "./components/index";


function App() {

  return (
    
      <BrowserRouter>
        <Routes>
          
          <Route exact={true} path="/" element={<Landing/>} />
          <Route exact={true} path="/about" element={<AboutPage/>} />
          <Route exact={true} path="/contact" element={<ContactUs/>} />
        </Routes>
      </BrowserRouter>
    
  );
}
export default App;
