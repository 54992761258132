import React, { useEffect, useState, useRef, createContext } from "react";
import { Navbar } from "../index";
import { useNavigate } from 'react-router-dom'
import './index.css'
import developerSVG from '../../developer.png';
import { User } from "discord-info"
export const homeRefContext = createContext();



export function Landing() {

   
    let navigate = useNavigate()
    function Redirect(url){
        navigate(url)
    }


    
    
    
    const homeRef = useRef()

  return (
    <div>
        <Navbar homeRef={homeRef}/>
        
        <div className="main">
            <div ref={homeRefContext} className="container-landing">
                <div className="sec-1-landing">
                    <h1>Hello, My name is <span>Ali</span></h1>
                    <h2><span>I am a </span>Back-end developer</h2>
                    <div className="btn-section-landing">
                        <button onClick={() => window.location.href = "https://discord.com/users/440181295708897291"}>Discord</button>
                        {/* &nbsp;
                            &nbsp;
                        <button onClick={() => window.location.href = "https://paypal.me/aliamara1712"}>Donation</button> */}
                    </div>
                    
                </div>
                <div className="sec-2-landing">
                {/* <img src={}/> */}
                </div>
            </div>
        </div>
        <div>
            
        </div>
        <div className="next-page">
        <i onClick={() => Redirect('/about')} class='bx bx-down-arrow-alt' ></i>
            </div>
      </div>
  );
  
}