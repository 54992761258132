import React, { useEffect, useState, useRef, createContext } from "react";
import { Navbar } from "../index";
import { useNavigate } from "react-router-dom";
import "./index.css";
import developerSVG from "../../developer.png";
export const homeRefContext = createContext();
export function AboutPage() {
  let navigate = useNavigate();
  function Redirect(url) {
    navigate(url);
  }


  

  const infoArr = ["Full Javascript Developer (MERN)", "Javascript - Node js", "Express js", "Rest API", "Database - MongoDB(Mongoose)"];
  return (
    <div>
      <Navbar />

      <div className="about">
        <h1>About Me</h1>
        <h3>
          Hello, I'm Ali.<br/>
My journey as a back-end developer started about 7 months ago. As for myself, I am a high school student who aspires to be a software engineer, and in this way, you are now in the first website that I create myself.
        </h3>
        <br/>
        <h1>Most used tools by me</h1>
        <ul >
        <li style={{color: "#a1a1a1"}}>Node.js</li>
            <li style={{color: "#a1a1a1"}}>Typescript</li>
            <li style={{color: "#a1a1a1"}}>Express</li>
            <li style={{color: "#a1a1a1"}}>MongoDB</li>
        </ul>
      </div>
      <div className="next-page-about">
        <i onClick={() => Redirect("/")} class="bx bx-up-arrow-alt"></i>
      </div>
      <div className="next-page">
        <i onClick={() => Redirect("/contact")} class="bx bx-down-arrow-alt"></i>
      </div>
    </div>
  );
}
